.create-scene {
  width: 700px !important;
  .create-scene-header {
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 2vw;
  }
  .project-selector,
  .scene-info {
    display: flex;
    width: fit-content;
    align-items: center;
    margin: 1vw auto;
    margin-top: 1vw;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba($color: #000000, $alpha: 0.6);

    .ant-select {
      width: 7vw;
      margin-right: 1rem;
    }
    .ant-select-selector {
      border-radius: 5px !important;
      text-align: center;
      color: rgba($color: #000000, $alpha: 0.6);
      font-size: 16px;
    }

    input {
      width: 11.5vw;
      border: 1px solid grey;
      border-radius: 5px;
      padding: 0rem 0.5rem;
      margin-right: 0.5rem;
    }

    ::placeholder {
      color: rgba($color: #000000, $alpha: 0.3);
      font-weight: 100;
    }

    .scene-name {
      cursor: pointer;
    }
  }

  .project-selector {
    label {
      font-weight: bold;
      margin-right: 1vw;
      margin-bottom: 0%;
    }
    .ant-select {
      width: 15vw;
      height: 100% !important;
      margin-right: 1vw;
    }
  }

  .templates-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 1rem;
  }

  .templates-grid {
    display: flex;
    flex-wrap: wrap;

    height: 430px;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background: #dadada;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .template-card {
      width: 33.333%;
      height: fit-content;
      padding: 0.25rem 0.5rem;
      text-align: center;

      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.7);

      cursor: pointer;

      .loader {
        justify-content: center;
        height: 100px;
        span {
          margin: auto;
          text-align: center;
          color: black !important;
          fill: black;
        }
      }

      .template-img {
        position: relative;
        height: 100px;
        background-size: cover !important;
        background-color: grey;
        border-radius: 10px;
        margin-bottom: 0.5rem;
        border: 3px solid;
        border-color: rgba($color: #ffffff, $alpha: 1);
      }

      .checkmark {
        height: 100%;
        width: 100%;
        background-color: rgba(26, 118, 232, 0.37);

        position: relative;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .price {
        position: absolute;
        width: fit-content;

        bottom: 0vw;
        right: 0vw;

        display: none;
        color: white;
        background-color: rgba(0, 0, 0, 0.7);
        width: fit-content;
        margin-left: auto;
        border-top-left-radius: 0.5vw;
        padding: 0.5vw 3vw;
        font-size: 1vw;
      }

      &:hover {
        .price {
          display: block;
        }
      }

      &:hover,
      &.selected {
        .template-img {
          border-color: #1a76e8;
        }

        .template-title {
          color: #1a76e8;
        }
      }
    }
  }

  .create-scene-footer {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    .ant-select {
      width: 120px;
      margin-left: 1rem;
    }
    .ant-select-selector {
      border-radius: 5px !important;
      text-align: center;
      color: rgba($color: #000000, $alpha: 0.6);
      font-size: 16px;
    }

    .cancel {
      margin-left: auto;
      margin-right: 1rem;

      button {
        padding: 0.25rem 1rem;
        background-color: white;
        border-radius: 5px;
        border: 1px solid rgba($color: #000000, $alpha: 0.3);
        color: rgba($color: #000000, $alpha: 0.7);
      }
    }

    .create button {
      padding: 0.25rem 1rem;
      border-radius: 5px;
      color: white;
      border: 1px solid #1a76e8;
      background-color: #1a76e8;
    }

    .loader {
      display: inline;
      margin-right: 0.5rem;
    }

    .create button:disabled {
      border: 1px solid #cecece;
      background-color: #cecece;
      cursor: not-allowed;
    }
  }
}
