.tutorials-modal {
  width: 55vw !important;
  height: 80vh;
  top: 0%;
  .ant-modal-content {
    background-color: rgba($color: #000000, $alpha: 0);
  }
  .ant-modal-body {
    padding: 0%;
    height: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
  }

  h1,
  h4 {
    text-align: center;
    font-weight: normal;
    color: white;
  }

  h1 {
    font-size: 3.125vw;
    padding-top: 2.3vw;
  }

  h4 {
    font-size: 1.25vw;
    margin-bottom: 1.25vw;
  }

  .close-btn {
    position: absolute;
    top: 7.8vw;
    left: -1.3vw;

    cursor: pointer;

    color: rgba($color: #ffffff, $alpha: 0.5);
    background-color: black;
    width: fit-content;
    padding: 0.5vw 1vw;
    border-radius: 50%;
    border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
  }

  iframe {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    background-color: black;
    border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
    border-radius: 8px;
  }
}
