.animations {
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  overflow-x: hidden;
  padding-top: 4vh;
  padding-left: 4vw;

  background-color: #100f10;
  color: white;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 2vw;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: rgba(255, 251, 251, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }

  .editor-mode-btn {
    display: block;
    background-color: black;
    border: 1px solid white;
    margin: 1vw;
    border-radius: 3vw;
    outline: none;
    color: white;
    font-size: 3.5vw;
  }

  .top-box {
    display: block;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    .search {
      margin: 0.75vw 1vw 1vw 0vw;
      // margin-top: 10vw;
      // width: calc(100% - 0.5rem);
      width: 78vw;

      .ant-input-search {
        padding: 0;
        border-radius: 5px;
        background-color: white;
        border: none;
        input {
          padding: 1vw 2vw;
          background-color: white;
          color: black;
          border-radius: 2vw;

          font-size: 3.5vw;
        }
        ::placeholder {
          color: #000000;
        }

        .ant-input-suffix {
          margin: 0px;
          margin-right: 3vw;
          background-color: white;
        }
      }
      button {
        display: none;
      }
    }
    .favorites-button {
      display: inline;
      margin: 1vw 2vw;
      svg {
        cursor: pointer;
        height: 100% !important;

        &:hover {
          fill: #fa6161;
        }
      }
    }
  }
  .animations-list {
    padding-left: 1vw;
    padding-right: 1vw;
    height: 100%;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2.5vw;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;

      background-color: rgba(255, 251, 251, 0.2);
      -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
      box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    }

    .animation {
      width: 100%;
      display: flex;
      align-items: center;

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 4vw !important;
      }

      .play {
        margin-right: 1vw !important;
      }
      .favorite {
        margin-left: auto;
        margin-right: 1vw !important;
      }
      .play,
      .pause,
      .add,
      .favorite {
        cursor: pointer;
        svg {
          width: 4vw;
          height: 4vw;
        }
      }
    }
  }
  .animations-tags {
    // padding-left: 1vw;
    // padding-right: 1vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .tag-box {
      padding: 1vw 3vw;
      margin: 1.5vw;
      width: fit-content;
      height: fit-content;
      border-radius: 2vw;
      font-size: 3vw;
    }
    .tag-box:hover {
      box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.4);
      cursor: pointer;
    }
    .tag-selected {
      width: 100%;
      border-radius: 1vw;
      position: relative;
      height: fit-content;
      font-size: 3.5vw;
      padding: 0.75vw 1vw;
      margin: 1vw 2vw 2vw 0vw;
      .tag-back {
        position: relative;
        left: 3vw;
      }
      .tag-text {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
}
