.edit-animation-modal {
  top: 10vw !important;
  .ant-modal-body {
    padding-top: 3vw;
    padding-bottom: 3vw;
  }
  form {
    font-size: 4vw;
    label {
      display: block;
      margin-top: 1vw;
      margin-bottom: 1vw;
    }

    .ant-input-number {
      width: 100%;
      & > div,
      input {
        height: 7vw;
      }
      input {
        font-size: 4vw;
        width: 100%;
        padding: 1vw;
        margin: 0vw;
      }
    }

    input[type='text'],
    select {
      width: 100%;
      padding: 1vw;
      margin: 1vw;
      margin-left: 0%;
      display: inline-block;
      border: 1px solid #ccc;
      box-sizing: border-box;
    }

    input[type='submit'] {
      width: 100%;
      background-color: #4caf50;
      color: white;
      padding: 2vw 3vw;
      margin: 3vw 0;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    input[type='submit']:hover {
      background-color: #45a049;
    }
  }
}
