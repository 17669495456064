.save-character-modal {
  .modal-title {
    margin: 0 0 3rem 0;
  }

  input {
    // width: 50%;
    margin-left: 1rem;
    margin-bottom: 3rem;
  }

  .button-container {
    text-align: right;

    button {
      font-size: 11px;
      margin-right: 0.5rem;
    }
  }
}
