.purchase-template-modal {
  width: 45vw !important;
  // .ant-modal-content,
  // .ant-modal-header {
  //   // background-color: #11202e;
  //   // color: white !important;
  // }
  .ant-modal-body {
    padding: 0%;
    padding-bottom: 2vw;
  }

  .loader {
    margin-top: 4rem;
    margin-bottom: 0rem;
    padding-top: 5vw;
  }

  .item-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5vw;
    width: 100%;

    .carousel-container {
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 0vw;
      margin-right: 0vw;
      margin-bottom: 1.5vw;
      width: 100%;

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 3vw;
      }

      .carousel-inner,
      .carousel-item,
      .carousel-img {
        height: 15vw;
        width: 100%;
      }
    }

    .price {
      width: fit-content;
      color: black;
      background-color: #dedede;
      padding: 0vw 2vw;
      margin: auto;
      border-radius: 1vw;
      font-weight: 500;
      line-height: 2.5vw;
      text-align: center;
      font-size: 1.25vw;
    }

    .name {
      color: #515151;
      margin-top: 0.5vw;
      margin-bottom: 1.5vw;
      text-align: center;
      font-size: 1.25vw;
    }
  }

  // .summary {
  //   margin: 3vw 6vw;
  //   p {
  //     font-size: 2vw;
  //     text-align: center;
  //     font-style: italic;
  //   }
  // }

  .footer {
    text-align: center;

    button {
      outline: none;
      border: none;
      border-radius: 1vw;
      padding: 0.25vw 2vw;
      font-size: 1.25vw;
      width: 10vw;
      color: white;
      font-weight: bold;
    }

    .cancel {
      background-color: #c4c4c4;
      margin-right: 3vw;
    }

    .submit {
      background-color: #1b76e8;
    }
  }
}
